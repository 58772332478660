// import { FaListCheck } from "react-icons/fa6";
// import { LuLayoutTemplate } from "react-icons/lu";
// import { IoCodeSlash } from "react-icons/io5";

const WorksData = [
  {
    id: 1,
    title: "成人式レポート2024 デザイン",
    imageUrl: "/works1.png",
    // genre: "デザイン",
    // detailRoute: "/project-one",
    productName: "My振袖.com",
    // duration: "1ヶ月",
    // roleIcons: [
    //   { role: "企画", icon: <FaListCheck /> },
    //   { role: "デザイン", icon: <LuLayoutTemplate /> },
    //   { role: "コーディング", icon: <IoCodeSlash /> },
    // ],
    roles: [
      "アンケートページの企画",
      "デザイン",
      "コーディング(HTML(Laravel Blade)・SCSS・jQuery)",
    ],
    productUrl: "https://s.myfurisode.com/report-2024",
    // beforeImageUrl: "url_to_before_image_1.png",
    // afterImageUrl: "url_to_after_image_1.png",
    background:
      "毎年実施している成人式取材を4年ぶりにオフラインで実施。サイトのあり方が見直しが必要になったことから新規でデザインすることとなった。",
    processes: [
      "企画を受領、ワイヤーフレーム作成",
      "全社でコンテンツ内容の確認を実施、その際に振袖に関するアンケート結果ページの新設が決定",
      "「2024年新成人の振袖選び」ページのワイヤーフレーム・デザイン作成に着手",
      "全社でデザインの確認会を実施",
      "実装・動作テストを経て公開",
    ],
    effects: [
      { title: "1ヶ月PV数", info: "+50%" },
      { title: "加盟店舗", info: "+3店舗" },
      { title: "販売広告枠", info: "2枠販売" },
    ],
  },
  {
    id: 2,
    title: "マイページ カタログ請求履歴 UI改修",
    imageUrl: "/works2.png",
    // genre: "デザイン",
    // detailRoute: "/project-two",
    productName: "My振袖.com",
    // duration: "2週間",
    // roleIcons: [
    //   { role: "デザイン", icon: <LuLayoutTemplate /> },
    //   { role: "コーディング", icon: <IoCodeSlash /> },
    // ],
    roles: ["UIデザイン", "コーディング(HTML(Laravel Blade)・SCSS)"],
    productUrl: "",
    // beforeImageUrl: "url_to_before_image_2.png",
    // afterImageUrl: "url_to_after_image_2.png",
    background:
      "CTAボタンを設置するだけの予定だったが実装ミスを発見、その解消に伴いUIの見直しが必要になった",
    processes: [
      "企画を受領、仕様調査",
      "本機能を実装した4年前から、請求したカタログが正常に表示されていないことが判明",
      "上記解消方法と店舗ごとに行の形のUIとした上でCTAボタンを設置することを提案",
      "実装・動作テストを経て公開",
    ],
    effects: [
      { title: "1ヶ月PV数", info: "+200%" },
      { title: "1ヶ月CV数", info: "+240%" },
      { title: "1ヶ月ユーザー数", info: "+90%" },
    ],
  },
  {
    id: 3,
    title: "採用サイト 制作",
    imageUrl: "/works3.png",
    // genre: "コーディング",
    // detailRoute: "/project-three",
    productName: "TeraDox",
    // duration: "4ヶ月",
    // roleIcons: [{ role: "コーディング", icon: <IoCodeSlash /> }],
    productUrl: "https://recruit.teradox.jp/",
    // beforeImageUrl: "url_to_before_image_3.png",
    // afterImageUrl: "url_to_after_image_3.png",
    background:
      "自社で求人出稿からエントリーができるようにすべく、採用サイトを新設",
    processes: [
      "デザイナーからデザインカンプを受領",
      "アニメーションなどの動的な部分の仕様を検討・提案",
      "WordPressで実装しつつ、エントリーフォーム周りのプラグインを選定・提案",
      "エントリーフォーム送信→slackに通知・スプレッドシートに転記するロジックを実装",
      "動作テスト",
      "社内の対応レクチャーを経て公開",
    ],
    roles: [
      "アニメーション仕様決定",
      "コーディング(HTML・SCSS・JavaScript・WordPress)",
      "求人応募対応フロー策定・レクチャー",
    ],
    effects: [
      { title: "求人掲載費用", info: "0" },
      { title: "求人掲載時の開発コスト", info: "0" },
      { title: "サイト経由のエントリー", info: "10人" },
    ],
  },
];

export default WorksData;
