import React from "react";

import {
  DiHtml5,
  DiIllustrator,
  DiJavascript1,
  DiPhotoshop,
  DiReact,
  DiSass,
} from "react-icons/di";
import { PiFigmaLogoLight } from "react-icons/pi";
import { FaVuejs } from "react-icons/fa6";

import WorksList from "./WorksList";

const TopPage = () => {
  return (
    <div className="top-page">
      <section className="top-page__section">
        <h1 className="top-page__section-title--page">Kakezan</h1>

        <div className="top-page__kv-paragraph-wrapper">
          <p className="top-page__kv-paragraph">
            お忙しい中、ご覧いただきありがとうございます！
          </p>

          <p className="top-page__kv-paragraph">
            都内のIT企業にてWebデザイナーとして勤務している竹内洋佑と申します。
            <br />
            Webサイトのデザイン・コーディングを担当しております。
          </p>

          <p className="top-page__kv-paragraph">
            建築の施工管理・法人営業からWebデザイナーに転職して1年、これまでの実績をまとめました！
            <br />
            施工管理で培った実装視点・法人営業で培った課題解決力とのKAKEZANでデザインをしております。
          </p>

          <p className="top-page__kv-paragraph">
            ぜひ、最後までご覧いただければ幸いです！
          </p>
        </div>
      </section>

      <section className="top-page__section">
        <h2 className="top-page__section-title">Works</h2>

        <WorksList />
      </section>

      <section className="top-page__section">
        <h2 className="top-page__section-title">About me</h2>

        <div className="top-page__section-flex">
          <div className="top-page__selfie">
            <img src={`${process.env.PUBLIC_URL}/my-image.png`} alt="自画像" />
          </div>

          <div className="top-page__text-contents-wrapper">
            <div className="top-page__text-contents">
              <h3 className="top-page__sub-title">プロフィール</h3>

              <div className="top-page__label-and-text-wrapper">
                <div className="top-page__label-and-text">
                  <div className="top-page__label">名前</div>
                  <p className="top-page__label-text">竹内洋佑</p>
                </div>

                <div className="top-page__label-and-text">
                  <div className="top-page__label">生年月日</div>
                  <p className="top-page__label-text">1997年1月17日</p>
                </div>

                <div className="top-page__label-and-text">
                  <div className="top-page__label">MBTI</div>
                  <p className="top-page__label-text">INFJ-T</p>
                </div>

                <div className="top-page__label-and-text">
                  <div className="top-page__label">趣味</div>
                  <p className="top-page__label-text">
                    バスケ ダーツ ラテアート
                  </p>
                </div>
              </div>
            </div>

            <div className="top-page__text-contents">
              <h3 className="top-page__sub-title">職種・スキル</h3>

              <div className="top-page__label-and-text-wrapper">
                <div className="top-page__label-and-text">
                  <div className="top-page__label">職種</div>
                  <p className="top-page__label-text">Webデザイナー</p>
                </div>

                <div className="top-page__label-and-text">
                  <div className="top-page__label">スキル</div>

                  <div className="top-page__text-and-icon-wrapper">
                    <div className="top-page__text-and-icon">
                      <p className="top-page__label-text">実務経験あり</p>
                      <div className="top-page__icon-wrapper">
                        <DiHtml5 />
                        <DiSass />
                        <DiJavascript1 />
                        <FaVuejs />
                        <PiFigmaLogoLight />
                        <DiPhotoshop />
                        <DiIllustrator />
                      </div>
                    </div>

                    <div className="top-page__text-and-icon">
                      <p className="top-page__label-text">習得中</p>
                      <div className="top-page__icon-wrapper">
                        <DiJavascript1 />
                        <DiReact />
                        <FaVuejs />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-page__section">
        <h2 className="top-page__section-title">Career</h2>

        <div className="top-page__text-contents-wrapper--no-flex">
          <div className="top-page__text-contents">
            <h3 className="top-page__sub-title">東急Re・デザイン</h3>

            <div className="top-page__label-and-text-wrapper">
              <div className="top-page__label-and-text">
                <div className="top-page__label">事業内容</div>
                <p className="top-page__label-text">
                  商業施設の内装デザイン・施工管理
                </p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">職種</div>
                <p className="top-page__label-text">施工管理・法人営業</p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">年数</div>
                <p className="top-page__label-text">
                  施工管理1年・法人営業2.5年
                </p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">実績</div>
                <p className="top-page__label-text">
                  自身が施工管理を行なった工事での労災発生0。
                  <br />
                  原宿の商業施設内装工事にて、先輩社員と共に総額6億円の入札を勝ち取る。
                </p>
              </div>
            </div>
          </div>

          <div className="top-page__text-contents">
            <h3 className="top-page__sub-title">TeraDox</h3>

            <div className="top-page__label-and-text-wrapper">
              <div className="top-page__label-and-text">
                <div className="top-page__label">事業内容</div>
                <p className="top-page__label-text">
                  自社ポータルサイトの運営、電子契約サービスの運営
                </p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">職種</div>
                <p className="top-page__label-text">Webデザイナー</p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">年数</div>
                <p className="top-page__label-text">1年</p>
              </div>

              <div className="top-page__label-and-text">
                <div className="top-page__label">実績</div>
                <p className="top-page__label-text">
                  自社運営公式LINE平均クリック率10.3%を達成。
                  <br />
                  自社採用サイトでの求人の掲載費用0・求人出稿時の開発コスト0を実現。
                  <br />
                  年始にラスベガスで行われるCES視察に参加、世界の技術開発の動向に触れる。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <p className="copyright">&copy;2024 Yosuke Takeuchi</p>
    </div>
  );
};

export default TopPage;
