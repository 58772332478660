import React from "react";
import WorksData from "./WorksData";
import { Link } from "react-router-dom";

const WorksList = () => {
  return (
    <>
      {WorksData.map((work) => (
        <div key={work.id} className="works-list">
          <div className="works-list__title-wrap">
            <span className="works-list__product-name">{work.productName}</span>
            <h3 className="works-list__project-title">{work.title}</h3>
          </div>

          <div className="works-list__image">
            <img src={work.imageUrl} alt={work.title} />
          </div>

          <Link to={`/project/${work.id}`} className="works-list__link">
            詳しく見る
          </Link>
        </div>
      ))}
    </>
  );
};

export default WorksList;
