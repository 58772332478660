import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import WorksData from "./WorksData";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="header">
        <Link to="/">
          <h1 className="header__site-name">
            Yosuke Takeuchi
            <br />
            Portfolio
          </h1>
        </Link>

        <div
          className={`header__burger-menu ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span className="header__burger-icon-border"></span>
          <span className="header__burger-icon-border"></span>
        </div>
      </header>

      <nav className={`header__burger-nav ${isOpen ? "open" : ""}`}>
        <ul className="header__nav-flex">
          <li className="header__nav-flex-item">
            <Link
              to="/#top"
              className={`header__nav-link ${
                location.hash === "#top" ? "active" : ""
              }`}
              data-product-name="Yosuke Takeuchi Portfolio"
              onClick={toggleMenu}
            >
              TOP
            </Link>
          </li>

          {WorksData.map((work) => (
            <li key={work.id} className="header__nav-flex-item">
              <Link
                to={`/project/${work.id}`}
                className={`header__nav-link ${
                  location.pathname === `/project/${work.id}` ? "active" : ""
                }`}
                data-product-name={`${work.productName}`}
                onClick={toggleMenu}
              >
                {work.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Header;
