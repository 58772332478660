import React, { useState } from "react";

const WorksTabMenu = ({ project }) => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="works-tab-menu">
      <div className="works-tab-menu__tab-buttons">
        <button
          onClick={() => handleTabClick("tab1")}
          className={`works-tab-menu__tab-button ${
            activeTab === "tab1" ? "active" : ""
          }`}
        >
          担当範囲
        </button>
        <button
          onClick={() => handleTabClick("tab2")}
          className={`works-tab-menu__tab-button ${
            activeTab === "tab2" ? "active" : ""
          }`}
        >
          背景・過程
        </button>
        <button
          onClick={() => handleTabClick("tab3")}
          className={`works-tab-menu__tab-button ${
            activeTab === "tab3" ? "active" : ""
          }`}
        >
          成果
        </button>
      </div>
      <div className="works-tab-menu__tab-content">
        {activeTab === "tab1" && (
          <ul className="works-tab-menu__tab-menu-list">
            {project.roles.map((role, index) => (
              <li key={index} className="works-tab-menu__tab-menu-list-item">
                {role}
              </li>
            ))}
          </ul>
        )}
        {activeTab === "tab2" && (
          <ol className="works-tab-menu__tab-menu-list">
            <p className="works-tab-menu__background-text">
              {project.background}
            </p>
            {project.processes.map((processes, index) => (
              <li
                key={index}
                className="works-tab-menu__tab-menu-list-item--number"
              >
                {processes}
              </li>
            ))}
          </ol>
        )}
        {activeTab === "tab3" && (
          <ul className="works-tab-menu__tab-menu-list">
            {project.effects.map((effect, index) => (
              <li key={index} className="works-tab-menu__tab-menu-list-item">
                {effect.title}：
                <span className="works-tab-menu__point-text">
                  {effect.info}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default WorksTabMenu;
