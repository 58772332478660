import React from "react";
import { useParams } from "react-router-dom";
import WorksData from "./WorksData";
import WorksTabMenu from "./WorksTabMenu";

const WorksDetail = () => {
  const { id } = useParams();
  const project = WorksData.find((project) => project.id === parseInt(id));

  if (!project) {
    return <div>プロジェクトが見つかりませんでした。</div>;
  }

  return (
    <div className="works-detail">
      <h1 className="works-detail__page-title">Works{project.id}</h1>

      <section className="works-detail__section--kv">
        <div className="works-detail__works-title-wrapper">
          <span className="works-detail__product-name">
            {project.productName}
          </span>
          <h2 className="works-detail__works-title">{project.title}</h2>
        </div>

        <div className="works-detail__kv">
          <img src={project.imageUrl} alt={project.title} />
        </div>

        {project.productUrl && (
          <a
            href={project.productUrl}
            className="works-detail__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            サイトを見る
          </a>
        )}
      </section>

      <section className="works-detail__section">
        <h2 className="works-detail__section-title">Details</h2>
        <WorksTabMenu project={project} />
      </section>

      <p className="copyright">&copy;2024 Yosuke Takeuchi</p>
    </div>
  );
};

export default WorksDetail;
