import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import TopPage from "./components/TopPage";
import WorksDetail from "./components/WorksDetail";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/" element={<TopPage />} />
          <Route path="/project/:id" element={<WorksDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
